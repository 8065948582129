body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'avenir';
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
}

.tastygreen {
  color: rgb(156, 211, 75)
}

.bg-tastygreen {
  background-color: rgb(156, 211, 75)
}

.pulse {
  animation: pulse 5s infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(.9, .9); /* Safari */
  }
  50% {
    -webkit-transform: scale(1.1, 1.1); /* Safari */
  }
  100% {
    -webkit-transform: scale(.9, .9); /* Safari */
  }
}

.babypulse {
  animation: babypulse 5s infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
}

@keyframes babypulse {
  0% {
    -webkit-transform: scale(.97, .97); /* Safari */
  }
  50% {
    -webkit-transform: scale(1, 1); /* Safari */
  }
  100% {
    -webkit-transform: scale(.97, .97); /* Safari */
  }
}

/* .fader {
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
}

.bg-fader {
  -webkit-transition: background-color .3s ease-in-out;
  -moz-transition: background-color .3s ease-in-out;
  -o-transition: background-color .3s ease-in-out;
  transition: background-color .3s ease-in-out;
} */

#cf2 img.transparent {
opacity:0;
}

/* Prevents background from scrolling when modal is open */

.ReactModal__Body--open {
  overflow: hidden;
}


.vh-95 { height: 95vh; }
.modal-size {  height:  100vh; }

@media screen and (min-width: 30em) {
  .h-95-ns { height: 95%; }
  .vh-95-ns {  height:  95vh; }
  .modal-size-ns {  height:  95vh; }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .h-95-m { height: 95%; }
  .vh-95-m {  height:  95vh; }
  .modal-size-m {  height:  95vh; }
}

@media screen and (min-width: 60em) {
  .h-95-l { height: 95%; }
  .vh-95-l {  height:  95vh; }
  .modal-l {  height:  95vh; }
}